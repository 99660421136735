define("cc-frontend/services/phantom-card-stacks", ["exports", "cc-frontend/lib/object-id-gen", "lodash-es", "@sentry/browser"], function (_exports, _objectIdGen, _lodashEs, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PhantomCardStacks = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class PhantomCardStacks extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "socket", _descriptor2, this);

      _initializerDefineProperty(this, "cardStackTemplateUpdater", _descriptor3, this);

      _defineProperty(this, "dateToCardStack", Object.create(null));

      _defineProperty(this, "cardStackIdToOwnerId", Object.create(null));

      _defineProperty(this, "cardStackIdToCardStackTemplateId", Object.create(null));

      _defineProperty(this, "cardStackIdCardCache", Object.create(null));
    }

    /**
     * We look for the owner (course or planbook) to see who owns this card stack
     * This helps us know if the owner has changed
     * @param cardStackId
     */
    getOwnerIdForCardStackId(cardStackId) {
      return this.cardStackIdToOwnerId[cardStackId];
    }
    /**
     * If the coure or planbook doesn't have a cardStack for a date, we
     * need to create one. This creates it, adds it to the local memory,
     * and then returns it
     *
     * @param type planbook or course
     * @param ownerId the id of the planbook or course
     * @param usedAs  wehther it's a lesson or routine
     * @param dateString
     * @param defaultPermissions the permissions we need to assign by default
     */


    findCardStackForDate(ownerId, usedAs, dateString, defaultPermissions, relationships, useCardStackSummary) {
      let type = useCardStackSummary ? "card-stack-summary" : "card-stack"; // Fetch the things

      let cardStackId = this.findCardStackIdForDate(ownerId, dateString);
      let cardStack = this.store.findInMemory(type, cardStackId); // if, in fact, we don't have it, we create it and insert it locally

      if (cardStack === null || cardStack === undefined) {
        // Convert the relationships array to a form for the cardStack
        let relationshipsMap = (0, _lodashEs.reduce)(relationships, (acc, [type, id]) => {
          acc[type] = {
            type,
            id
          };
          return acc;
        }, {});
        this.store.insertDocument({
          id: cardStackId,
          type: type,
          attributes: useCardStackSummary ? this._createCardStackSummaryAttributes(usedAs) : this._createCardStackAttributes(usedAs),
          relationships: relationshipsMap,
          meta: {
            isNewLesson: true,
            permissions: defaultPermissions,
            cardsUpdatingFromTemplate: true
          }
        });
        cardStack = this.store.findInMemory(type, cardStackId); // We make this subscribe call b/c if it's a new lesson, we didn't subscribe to the ID we created
        // so, if this computed property is hit and we end up in this branch, it means it's
        // a lesson that was already created or one that was just created. Since the `subscribe`
        // call is idempotent, it's not a problem to hit it multiple times.

        this.socket.subscribe(type, cardStackId);
      } // Give it back.


      return cardStack;
    }
    /**
     * If there's a template and the cardStack is updating from it,
     * we need to add the template's cards to the cardStack
     */


    applyTemplateCardsToCardStackIfNeeded(cardStackId, cardStackTemplateId) {
      // Find the card stack locally
      let cardStack = this.store.findInMemory("card-stack", cardStackId); // Does it need cards? Just return if so

      let cardStackNeedsCards = cardStack && cardStack.meta.cardsUpdatingFromTemplate || !Ember.isNone(cardStack) && !Ember.isNone(cardStackTemplateId) && cardStack.attributes.cards === null; // if cards are [], they're set. if they're null, we use the template

      if (cardStackNeedsCards !== true) return; // If we have a template, we should add the template's cards

      if (cardStackTemplateId && cardStackId) {
        // if there's a template, we should add the template's cards
        this.store.find("card-stack", cardStackTemplateId).then(cardStackTemplate => {
          var _cardStackTemplate$at;

          if (cardStackTemplate === undefined) {
            Sentry.withScope(function (scope) {
              scope.setLevel(Sentry.Severity.Error);
              scope.setExtra("CardStackTemplateId", cardStackTemplateId);
              scope.setExtra("cardStackId", cardStackId);
              Sentry.captureMessage("CardStackTemplate is undefined.");
            });
          }

          let cards = (0, _lodashEs.map)((0, _lodashEs.cloneDeep)((cardStackTemplate === null || cardStackTemplate === void 0 ? void 0 : (_cardStackTemplate$at = cardStackTemplate.attributes) === null || _cardStackTemplate$at === void 0 ? void 0 : _cardStackTemplate$at.cards) || []), card => {
            // we want to keep the ids the same so updates don't wipe everything out.
            let cardLookupId = `${cardStackId}${card.id}`;

            let newId = this.cardStackIdCardCache[cardLookupId] = this.cardStackIdCardCache[cardLookupId] || _objectIdGen.default.create();

            card.attributes.parentCardId = card.id;
            card.id = newId;
            return card;
          });
          Ember.set(cardStack.attributes, "cards", cards);
          Ember.set(cardStack.attributes, "parentCardStack", {
            id: cardStackTemplate.id,
            version: cardStackTemplate.version
          });
          cardStack.meta.cardsUpdatingFromTemplate = true;
          return cardStack;
        }); // set up watcher to update the cardStackTemplate cards when it changes

        this.cardStackIdToCardStackTemplateId[cardStackId] = cardStackTemplateId;
        this.cardStackTemplateUpdater.subscribe(cardStackId, cardStackTemplateId);
      } else {
        // if there isn't a template, we should set the cards to an empty array []
        Ember.set(cardStack.attributes, "cards", []);
      }
    }
    /**
     * We create a cardStackId for a routine or lesson and then cache it
     * so the next time we request it ƒor that date, we get the same id
     * @param type a planbook or a course
     * @param typeId
     * @param dateString
     * @returns id
     */


    findCardStackIdForDate(ownerId, dateString) {
      let compositeId = `${ownerId}:${dateString}`;
      let cardStackId = this.dateToCardStack[compositeId]; // We check the owner in case the lesson got moved to a different course

      let ownerOfCardStack = this.cardStackIdToOwnerId[cardStackId];
      let hasDifferentOwner = ownerOfCardStack !== undefined && ownerOfCardStack !== ownerId;

      if (cardStackId && !hasDifferentOwner) {
        return cardStackId;
      } else {
        let id = _objectIdGen.default.create(); // We record the new owner so we can tell if it changes in the future


        this.cardStackIdToOwnerId[id] = ownerId;
        this.dateToCardStack[compositeId] = id;
        return id;
      }
    }
    /**
     * We need to invalidate a date. For instance, if the date is moved.
     *
     */


    invalidateDate(ownerId, cardStackId, dateString) {
      let compositeId = `${ownerId}:${dateString}`; // First, we invalidate the cardStackId to ownerId so we can say the owner changed

      if (cardStackId !== null) this.cardStackIdToOwnerId[cardStackId] = undefined; // Then, we invalidate the dateToCardStack array
      // should these both be set to undefined? Probably?

      this.dateToCardStack[compositeId] = null;
    }

    _createCardStackAttributes(usedAs) {
      return {
        title: null,
        subtitle: null,
        usedAs: usedAs,
        cards: null,
        parentCardStack: null,
        flags: {},
        version: 0,
        versionNarrative: {},
        _revision: null,
        _revisedBySessionId: null,
        color: null
      };
    }

    _createCardStackSummaryAttributes(usedAs) {
      return {
        title: null,
        color: null,
        usedAs: usedAs,
        _revision: null
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cardStackTemplateUpdater", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PhantomCardStacks;
});