define("cc-frontend/lib/actions/lesson/LESSON_CARD_ADD_ATTACHMENTS", ["exports", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _lodashEs, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_ADD_ATTACHMENTS = void 0;
  const LESSON_CARD_ADD_ATTACHMENTS = {
    name: "LESSON_CARD_ADD_ATTACHMENTS",

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      }).push("embedded", "attributes.attachments", ["$each", payload.files]).patch;

      return [patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.sectionId
      });

      patch.set("embedded", "attributes.attachments", payload.originalAttachments);
      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let titleForAnalytics = "Attachment - Other";
      let fileCount = (0, _lodashEs.get)(payload, "files.length");
      let fileCountInWords = fileCount === 1 ? "1 File" : `${fileCount} files`;
      let card = (0, _lodashEs.find)(models === null || models === void 0 ? void 0 : models.cardStack.attributes.cards, {
        id: payload.sectionId
      });
      if (card === undefined) throw new Error("No Card passed in card stack to comment on ");
      let cardTitle = "title" in card.attributes ? card.attributes.title : null;
      let attachmentTitles = (0, _lodashEs.map)(payload.files, file => {
        return file.title;
      });
      let context = (0, _defaultLessonContext.default)(payload, models);
      let newContext = (0, _lodashEs.assign)({}, context, {
        cardTitle: cardTitle,
        fileCount: fileCount,
        attachmentTitles
      });
      let attachmentId = (0, _lodashEs.get)(payload.originalAttachments, "id");
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "attachments",
        adverbial_verb: "adding",
        adverbial_object_type: "attachment",
        adverbial_object_specifier: "other",
        adverbial_object_id: attachmentId,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };

      if (payload.source === "drag") {
        titleForAnalytics = "Attachment - Upload File";
        newEvent.adverbial_object_specifier = "upload_file";
      } else if (payload.source === "dropbox") {
        titleForAnalytics = "Attachment - Add from Dropbox";
        newEvent.adverbial_object_specifier = "dropbox_file";
      } else if (payload.source === "google") {
        titleForAnalytics = "Attachment - Add from Google Drive";
        newEvent.adverbial_object_specifier = "google_drive_file";
      } else if (payload.source === "onedrive") {
        titleForAnalytics = "Attachment - Add from OneDrive";
        newEvent.adverbial_object_specifier = "one_drive_file";
      } else {
        null;
      }

      return {
        title: "Attached a File",
        titleForAnalytics: titleForAnalytics,
        description: `Added ${fileCountInWords} to "${cardTitle}" card`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models, "created"),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CARD_ADD_ATTACHMENTS = LESSON_CARD_ADD_ATTACHMENTS;
  var _default = LESSON_CARD_ADD_ATTACHMENTS;
  _exports.default = _default;
});