define("cc-frontend/lib/actions/course/calendar/MOVE_LESSON_TO_DATE_ON_DIFFERENT_COURSE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "lodash-es", "moment", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _unitUtils, _lodashEs, _moment, _defaultLessonModels, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "MOVE_LESSON_TO_DATE_ON_DIFFERENT_COURSE",

    patches(payload) {
      // --------------------------------------------------
      // Remove lesson from old course
      // --------------------------------------------------
      let courseDatesWithoutLesson = (0, _lodashEs.map)(payload.fromCourse.attributes.calendar.dates, courseDate => {
        if (courseDate.attributes.cardStackId === payload.cardStackId) {
          let cd = (0, _lodashEs.cloneDeep)(courseDate);
          cd.attributes.cardStackId = null;
          return cd;
        } else {
          return courseDate;
        }
      }); // --------------------------------------------------
      // Insert lesson
      // --------------------------------------------------

      let transferrableArray = [{
        cardStackId: payload.cardStackId,
        unitStart: [],
        unitEnd: []
      }];
      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.toCourse, payload.planbook, payload.rotationCalendar);
      let newCourseDates = (0, _unitUtils.insertRange)(transferrableArray, payload.toDate, 0, null, payload.toCourse.attributes.calendar.dates, consolidatedCourseDatesOff, payload.rotationCalendar.attributes.schoolDays, true);
      let {
        courseDates,
        units
      } = (0, _unitUtils.constructUnitsAndDates)(newCourseDates, consolidatedCourseDatesOff, payload.rotationCalendar.attributes.schoolDays); // --------------------------------------------------
      // Create patches
      // --------------------------------------------------
      // We might be creating a lesson here, so we need to set this

      let updateLessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("relationships.course.data.id", payload.toCourse.id).set("relationships.planbook.data.id", payload.planbook.id).set("attributes.usedAs", "lesson").set("meta.permissions", payload.toCourse.meta.permissions).patch; // to make sure we have the right permissions if this is new


      let removeFromOldCoursePatch = _patchUpdater.default.create("course", payload.fromCourse.id).set("attributes.calendar.dates", courseDatesWithoutLesson).inc("attributes.calendar.version", 1).patch;

      let addToNewCoursePatch = _patchUpdater.default.create("course", payload.toCourse.id).set("attributes.calendar.dates", courseDates).set("attributes.calendar.units", units).inc("attributes.calendar.version", 1).patch;

      return [updateLessonPatch, removeFromOldCoursePatch, addToNewCoursePatch];
    },

    undoPatches(payload) {
      let updateLessonPatch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("relationships.course.data.id", payload.fromCourse.id).patch;

      let removeFromOldCoursePatch = _patchUpdater.default.create("course", payload.fromCourse.id).set("attributes.calendar.dates", payload.fromCourse.attributes.calendar.dates).inc("attributes.calendar.version", 1).patch;

      let addToNewCoursePatch = _patchUpdater.default.create("course", payload.toCourse.id).set("attributes.calendar.dates", payload.toCourse.attributes.calendar.dates).set("attributes.calendar.units", payload.toCourse.attributes.calendar.units).inc("attributes.calendar.version", 1).patch;

      return [updateLessonPatch, removeFromOldCoursePatch, addToNewCoursePatch];
    },

    narrative(payload, findFn, userId) {
      let fromCourse = payload.fromCourse;
      let toCourse = payload.toCourse;
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let lessonTitle = (0, _lodashEs.get)(models, "lesson.attributes.title");
      let fromCourseTitle = (0, _lodashEs.get)(models, "fromCourse.attributes.title");
      let toCourseTitle = (0, _lodashEs.get)(models, "toCourse.attributes.title");
      let formattedFromDate = (0, _moment.default)(payload.fromDate).format("ddd, MMM. D, YYYY");
      let formattedToDate = (0, _moment.default)(payload.toDate).format("ddd, MMM. D, YYYY");
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: [payload.planbook.id, payload.fromCourse.id],
        primary_verb: "move",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: null,
        adverbial_verb: "dragging_dropping",
        adverbial_object_type: "lesson",
        adverbial_object_specifier: "to_different_class",
        adverbial_object_id: payload.cardStackId,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: [payload.planbook.id, payload.toCourse.id]
      };
      return {
        title: "Moved Lesson",
        titleForAnalytics: "Lesson - Move to different class",
        description: `Moved "${lessonTitle}" from ${formattedFromDate} in "${fromCourseTitle}" to ${formattedToDate} in "${toCourseTitle}"`,
        context: {
          lessonTitle: models.cardStack.attributes.title,
          fromCourseTitle: payload.fromCourse.attributes.title,
          toCourseTitle: payload.toCourse.attributes.title,
          planbookTitle: payload.planbook.attributes.title,
          cardStackId: payload.cardStackId,
          fromCourseId: payload.fromCourse.id,
          toCourseId: payload.toCourse.id,
          planbookId: payload.planbook.id
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${payload.fromCourse.id}`,
          to: [`course-edits:course-${(0, _lodashEs.get)(models, "fromCourse.id")}`, `planbook-edits:planbook-${(0, _lodashEs.get)(models, "planbook.id")}`]
        },
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.default = _default;
});